import { DashboardOutlined, GroupOutlined, UnorderedListOutlined, UserOutlined, CreditCardOutlined } from "@ant-design/icons";
import { App, ConfigProvider, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { EndeavourProvider, useEndeavour } from "../hooks/useEndeavour";

const menuItems = [
  {
    key: "/",
    icon: <DashboardOutlined />,
    label: <Link to="/">Dashboard</Link>,
  },
  {
    key: "/users",
    icon: <UserOutlined />,
    label: <Link to="/users">Users</Link>,
  },
  {
    key: "/creditcards",
    icon: <GroupOutlined />,
    label: <Link to="/creditcards">Cards</Link>,
  },
  {
    key: "/notransaction",
    icon: <UnorderedListOutlined />,
    label: <Link to="/notransaction">No Transactions List</Link>,
  },
  // {
  //   key: "/inventory",
  //   icon: <UnorderedListOutlined />,
  //   label: <Link to="/inventory">Inventory</Link>,
  // },
];

const bestMatchingKey = (pathname, menuItems) => {
  let bestMatch = "";
  menuItems.forEach((item) => {
    if (pathname.startsWith(item.key) && item.key.length > bestMatch.length) {
      bestMatch = item.key;
    }
  });
  return bestMatch || "/";
};

function Container() {
  const { token } = theme.useToken();
  const endeavour = useEndeavour();
  const [customTheme, setCustomTheme] = useState({
    token: {
      fontSize: 13,
      fontFamily: '"Binance PLEX", "IBM Plex Sans", "Noto Sans TC", "Noto Sans SC", sans-serif',
      padding: 16,
    },
    components: {
      // Table: {
      //   fontFamily: "'Goldman Sans'",
      // },
      // Statistic: {
      //   fontFamily: "'Goldman Sans'",
      // },
    },
    // algorithm: [theme.compactAlgorithm],
    // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
  });

  const currentPath = window.location.pathname;
  const defaultSelectedKey = bestMatchingKey(currentPath, menuItems);

  return (
    <ConfigProvider theme={customTheme}>
      <App>
        <EndeavourProvider>
          <Layout
            style={{
              minHeight: "100vh",
            }}
          >
            <Layout.Sider
              style={{
                padding: token.padding / 2,
              }}
            >
              <Menu theme="dark" defaultSelectedKeys={[defaultSelectedKey]} items={menuItems} />
            </Layout.Sider>
            <Layout>
              <Layout.Content
                style={{
                  padding: token.padding,
                }}
              >
                <Outlet />
              </Layout.Content>
              <Layout.Footer>Powered by Payment Asia</Layout.Footer>
            </Layout>
          </Layout>
        </EndeavourProvider>
      </App>
    </ConfigProvider>
  );
}

export default Container;
