import { BellOutlined } from "@ant-design/icons";
import { timestampToLocalTime } from "anka-endeavour";
import { Button, Form, Input, Select } from "antd";
import { useRef, useState } from "react";
import DataTable from "../components/DataTable";
import Status from "../components/Status";
import UserContactDrawer from "../components/UserContactDrawer";
import { hostname } from "../hooks/useEndeavour";
import { Link } from "react-router-dom";

function UsersPage() {
  const [forceUpdate, setForceUpdate] = useState(0);
  const drawerRef = useRef();

  const onUpdateFeeGroup = () => {
    setForceUpdate(forceUpdate + 1);
  };

  const onClickContact = (data) => {
    drawerRef.current.openDrawer(data);
  };

  return (
    <>
      <UserContactDrawer ref={drawerRef} onChange={onUpdateFeeGroup} />
      <DataTable
        tableSize="small"
        uri={`${hostname}/pa-alliance/users`}
        ForceUpdate={forceUpdate}
        filters={[
          <Form.Item label="Email" name="email">
            <Input autoFocus allowClear />
          </Form.Item>,
          <Form.Item label="E-KYC Status" name="kyc_id">
            <Select
              allowClear
              options={[
                { label: "Verified", value: "1" },
                { label: "Not Verify", value: "0" },
              ]}
            />
          </Form.Item>,
          <Form.Item label="Email Status" name="email_verified">
            <Select
              allowClear
              options={[
                { label: "Verified", value: "1" },
                { label: "Not Verify", value: "0" },
              ]}
            />
          </Form.Item>,
          <Form.Item label="Mobile Status" name="mobile_verified">
            <Select
              allowClear
              options={[
                { label: "Verified", value: "1" },
                { label: "Not Verify", value: "0" },
              ]}
            />
          </Form.Item>,
          <Form.Item label="Not Contacted Time" name="contacted_at">
            <Select
              allowClear
              options={[
                {
                  value: "30",
                  label: "30 Days",
                },
                {
                  value: "60",
                  label: "60 Days",
                },
                {
                  value: "90",
                  label: "90 Days",
                },
                {
                  value: "120",
                  label: "120 Days",
                },
                {
                  value: "360",
                  label: "360 Days",
                },
              ]}
            />
          </Form.Item>,
        ]}
        tableColumns={[
          {
            title: "User",
            align: "center",
            render: (data) => {
              return <Link to={`/users/${data.id}`}>{data.email}</Link>;
            },
          },
          ,
          {
            title: "Mobile",
            align: "center",
            render: (data) => {
              return data.mobile;
            },
          },
          {
            title: "Email Verified",
            align: "center",
            render: (data) => {
              return <Status value={data.email_verified === false ? "FAILED" : "SUCCESS"} />;
            },
          },
          {
            title: "Mobile Verified",
            align: "center",
            render: (data) => {
              return <Status value={data.mobile_verified === false ? "FAILED" : "SUCCESS"} />;
            },
          },
          {
            title: "EKYC",
            align: "center",
            render: (data) => {
              return <Status value={data.kyc_id === null ? "FAILED" : "SUCCESS"} />;
            },
          },
          {
            title: "Status",
            align: "center",
            render: (data) => {
              return <Status value={data.status} />;
            },
          },
          {
            title: "Time",
            align: "center",
            render: (data) => {
              return timestampToLocalTime(data.created_at);
            },
          },
          {
            title: "Contacted Time",
            align: "center",
            render: (data) => {
              if (data.contacted_at === null) {
                return null;
              }
              return timestampToLocalTime(data.contacted_at);
            },
          },
          {
            title: "Action",
            align: "center",
            render: (data) => {
              return (
                <Button type="primary" icon={<BellOutlined />} onClick={() => onClickContact(data)}>
                  Contacted User
                </Button>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default UsersPage;
