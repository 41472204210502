import { Form, Input } from "antd";
import Amount from "../components/Amount";
import DataTable from "../components/DataTable";
import Status from "../components/Status";
import { hostname, useEndeavour } from "../hooks/useEndeavour";

function NoTransactionsUserPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      uri={`${hostname}/pa-alliance/user/card-no-transaction`}
      filters={[
        <Form.Item label="User" name={"email"}>
          <Input />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "User",
          align: "center",
          render: (data) => {
            console.log(data.user);
            return data.email;
          },
        },
        {
          title: "Mobile",
          align: "center",
          render: (data) => {
            return data.mobile;
          },
        },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },

        {
          title: "Total Amount",
          align: "center",
          render: (data) => {
            return <Amount value={data.total_amount} currency="USD" />;
          },
        },
        {
          title: "Action",
          align: "center",
          render: (data) => {},
        },
      ]}
    />
  );
}

export default NoTransactionsUserPage;
