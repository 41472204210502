import { useQuery } from "@tanstack/react-query";
import { Card, Col, Row, Skeleton, theme, Typography } from "antd";
import ColoredStatistic from "../components/ColoredStatistic";
import { hostname } from "../hooks/useEndeavour";

const { Text } = Typography;
const styleCardMetric = {
  textAlign: "right",
};
const formatter = (value) => <CountUp end={value} separator="," />;
function DashboardPage() {
  const { token } = theme.useToken();

  const queryDashboard = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => {
      return fetch(`${hostname}/pa-alliance/user/dashboard`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          return res.payload[0];
        });
    },
  });

  if (queryDashboard.isFetching) {
    return <Skeleton />;
  }

  return (
    <>
      {console.log(queryDashboard.data?.have_kyc)}
      <Row gutter={[token.padding, token.padding]}>
        <Col span={24}>
          <Card title="User Verification Status">
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="EKYC" value={queryDashboard.data.have_kyc} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Email Verified" value={queryDashboard.data.have_email_verified} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Mobile Verified" value={queryDashboard.data.have_mobile_verified} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Bound Card User." value={queryDashboard.data.users_with_cards} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic
                title="User Have Transaction (3 Month)"
                value={queryDashboard.data.users_with_transaction}
                precision={2}
                formatter={formatter}
              />
            </Card.Grid>
          </Card>

          <Card title="Users Without Verification">
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="No EKYC" value={queryDashboard.data.no_kyc} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Email Non Verified" value={queryDashboard.data.no_email_verified} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Mobile Non Verified" value={queryDashboard.data.no_mobile_verified} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic title="Non Bound Card" value={queryDashboard.data.users_without_cards} precision={2} formatter={formatter} />
            </Card.Grid>
            <Card.Grid style={{ ...styleCardMetric, width: "20%" }} hoverable={false}>
              <ColoredStatistic
                title="No Transaction User (3 Month)"
                value={queryDashboard.data.users_without_transaction}
                precision={2}
                formatter={formatter}
              />
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardPage;
