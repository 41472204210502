import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Row, Form, Input, Button, Col, Drawer, Descriptions, Divider, Card, message, Radio, Select, Space } from "antd";
import Status from "./Status";
import { timestampToLocalTime } from "anka-endeavour";
import { useMutation } from "@tanstack/react-query";
import { hostname } from "../hooks/useEndeavour";
import axios from "axios";
const UserContactDrawer = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [type, setType] = useState("email");
  const [loading, setLoading] = useState(false);

  const contactUser = useMutation({
    mutationFn: (data) => {
      return axios
        .post(`${hostname}/pa-alliance/user/contact`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          message.success("User contacted successfully!");
          props.onChange();
          closeDrawer();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && typeof err.response.data.error.message === "string") {
            message.error(err.response.data.error.message);
          } else {
            message.error("Something went wrong.");
          }
        });
    },
  });

  const onFinish = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      values.email = currentUser?.email;
      contactUser.mutate(values);
    });
  };
  const openDrawer = (data) => {
    setCurrentUser(data);
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
    setLoading(false);
    form.resetFields();
  };

  useImperativeHandle(ref, () => ({
    openDrawer,
  }));

  const template = (value) => {
    switch (value) {
      case "hello":
        if (type === "email") {
          form.setFieldValue("subject", "Hello");
        }
        form.validateFields;
        form.setFieldValue("content", "Hello");
        break;
      case "email":
        if (type === "email") {
          form.setFieldValue("subject", "Email Validate");
        }
        form.setFieldValue("content", "Hi Sir \nSeem there have not validate the email. May i know xxxxxxx");
        break;
      case "mobile":
        if (type === "email") {
          form.setFieldValue("subject", "Mobile Validate");
        }
        form.setFieldValue("content", "Hi Sir \nSeem there have not validate the mobile. May i know xxxxxxx");
        break;
      default:
        form.resetFields();
    }
  };

  return (
    <Drawer title="User Information" open={open} onClose={closeDrawer} width={"800px"}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Descriptions column={2} layout="vertical">
            <Descriptions.Item label="User">{currentUser?.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status value={currentUser?.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Registration Time">{timestampToLocalTime(currentUser?.created_at)}</Descriptions.Item>
            <Descriptions.Item label="Last Contact Time">{timestampToLocalTime(currentUser?.contacted_at)}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain>
            Verification Status
          </Divider>
          <Descriptions layout="vertical">
            <Descriptions.Item label="Email">
              <Status value={currentUser?.email_verified === false ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">
              <Status value={currentUser?.mobile_verified === false ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
            <Descriptions.Item label="EKYC">
              <Status value={currentUser?.kyc_id === null ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain></Divider>
          <Card
            title=" Contact User"
            extra={
              <Space>
                <Button type="primary" onClick={() => template("hello")}>
                  Hello
                </Button>
                <Button type="primary" onClick={() => template("email")}>
                  Email
                </Button>
                <Button type="primary" onClick={() => template("mobile")}>
                  Mobile
                </Button>
                <Select
                  onChange={(e) => {
                    template(e);
                  }}
                  style={{
                    width: "90px",
                  }}
                  allowClear
                  options={[
                    {
                      value: "hello",
                      label: "Hello",
                    },
                    {
                      value: "email",
                      label: "Email",
                    },
                    {
                      value: "mobile",
                      label: "Mobile",
                    },
                  ]}
                />
              </Space>
            }
          >
            <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ type: "email" }}>
              <Form.Item
                label="Contact Method"
                name={"type"}
                rules={[
                  {
                    required: true,
                    message: "Please select Content Method !",
                  },
                ]}
              >
                <Radio.Group
                  defaultValue={"email"}
                  options={[
                    {
                      value: "email",
                      label: "Email",
                    },

                    {
                      value: "telegram",
                      label: "Telegram",
                    },

                    {
                      value: "whatsapp",
                      label: "WhatsApp",
                    },
                    {
                      value: "wechat",
                      label: "Wechat",
                    },
                    {
                      value: "sms",
                      label: "SMS",
                    },
                  ]}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
              </Form.Item>

              {type === "email" && (
                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input Subject!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                label="Content"
                name="content"
                rules={[
                  {
                    required: true,
                    message: "Please input Content !",
                  },
                ]}
              >
                <Input.TextArea rows={9} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Drawer>
  );
});

export default UserContactDrawer;
