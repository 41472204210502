import { LoadingOutlined, LoginOutlined } from "@ant-design/icons";
import { Button, Card, Col, ConfigProvider, Form, Input, Layout, Row, theme } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { hostname } from "../hooks/useEndeavour";

const GlobalTheme = {
  token: {
    fontSize: 13,
    fontFamily: '"Binance PLEX", "IBM Plex Sans", "Noto Sans TC", "Noto Sans SC", sans-serif',
    padding: 16,
  },
  //   algorithm: [theme.darkAlgorithm],
};

const withAuthentication = (WrappedComponent) => {
  const WithAuthenticationComponent = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"));

    const onFinish = (values) => {
      if (!values.username || !values.password || !values.code) {
        return;
      }

      const params = new URLSearchParams();
      params.append("client_id", "b861cfce-5ce4-4518-b88a-896e4b0c9317");
      params.append("grant_type", "password");
      params.append("username", values.username);
      params.append("password", values.password);
      params.append("code", values.code);
      params.append("scope", "endeavour endeavour.write");
      setLoading(true);

      axios
        .post(`${hostname}/oauth2.0/token`, params)
        .then((res) => {
          if (!res.data.code || res.data.code !== 200) {
            return;
          }
          localStorage.setItem("access_token", res.data.payload.access_token);
          setAccessToken(res.data.payload.access_token);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useEffect(() => {
      setIsLoading(true);
      const checkAuth = () => {
        fetch(`${hostname}/oauth2.0/me`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.code !== 200) {
              return false;
            }
            setIsAuthenticated(true);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      checkAuth();
    }, [accessToken]);

    if (isLoading) {
      return (
        <ConfigProvider theme={GlobalTheme}>
          <Layout>
            <Layout.Content>
              <Row style={{ minHeight: "100vh" }} justify={"center"} align={"middle"}>
                <LoadingOutlined
                  style={{
                    animationDuration: ".25s",
                  }}
                />
              </Row>
            </Layout.Content>
          </Layout>
        </ConfigProvider>
      );
    }

    if (!isAuthenticated) {
      return (
        <ConfigProvider theme={GlobalTheme}>
          <Layout>
            <Layout.Content>
              <Row style={{ minHeight: "100vh" }} justify={"center"} align={"middle"}>
                <Col span={6}>
                  <Card title="Restricted Area">
                    <Form layout="vertical" onFinish={onFinish}>
                      <Form.Item name="username">
                        <Input placeholder="Username" autoFocus />
                      </Form.Item>
                      <Form.Item name="password">
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                      <Form.Item name="code">
                        <Input placeholder="Google Authenticator" />
                      </Form.Item>
                      <Form.Item>
                        <Button loading={loading} block htmlType="submit" type="primary" icon={<LoginOutlined />}>
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Layout.Content>
          </Layout>
        </ConfigProvider>
      );
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthenticationComponent;
};

export default withAuthentication;
