import { useQuery } from "@tanstack/react-query";
import { Card, Col, DatePicker, Descriptions, Form, Input, Row, Skeleton, Tabs } from "antd";
import axios from "axios";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { hostname } from "../hooks/useEndeavour";
import Status from "../components/Status";
import { timestampToLocalTime } from "anka-endeavour";
import DataTable from "../components/DataTable";

function UserPage() {
  const [forceUpdate, setForceUpdate] = useState(0);
  const drawerRef = useRef();
  const { userID } = useParams();
  const query = useQuery({
    queryKey: ["users", userID],
    queryFn: () => {
      return axios
        .get(`${hostname}/pa-alliance/user/${userID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          return res.data.payload;
        });
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  const onUpdateFeeGroup = () => {
    setForceUpdate(forceUpdate + 1);
  };

  const onClickContact = (data) => {
    drawerRef.current.openDrawer(data);
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "contact_log":
        return (
          <DataTable
            uri={`${hostname}/pa-alliance/user/contact`}
            filters={[
              <Form.Item label="Subject" name="subject">
                <Input />
              </Form.Item>,
              <Form.Item label="Content" name="content">
                <Input />
              </Form.Item>,
              <Form.Item label="Date Range" name="created_at">
                <DatePicker.RangePicker />
              </Form.Item>,
              <Form.Item name={"card_user_id"} hidden>
                <Input />
              </Form.Item>,
            ]}
            filtersInitialValues={{
              card_user_id: query.data?.id,
            }}
            tableColumns={[
              {
                title: "Sender",
                dataIndex: "sender",
                key: "sender",
              },
              {
                title: "To",
                dataIndex: "to",
                key: "to",
              },
              {
                title: "Type",
                dataIndex: "type",
                key: "type",
              },
              {
                title: "Subject",
                dataIndex: "subject",
                key: "subject",
              },
              {
                title: "Content",
                dataIndex: "content",
                key: "content",
              },
              {
                title: "Date",
                dataIndex: "created_at",
                key: "created_at",
                render: (data) => timestampToLocalTime(data),
              },
              {
                title: "Created By",
                dataIndex: "created_by",
                key: "created_by",
              },
            ]}
          />
        );
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card title={"User Info"}>
          <Descriptions layout="vertical">
            <Descriptions.Item label="User">{query.data?.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status value={query.data?.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Registration Time">{timestampToLocalTime(query.data?.created_at)}</Descriptions.Item>
            <Descriptions.Item label="Last Contact Time">{timestampToLocalTime(query.data?.contacted_at)}</Descriptions.Item>
            <Descriptions.Item label="Last 3 months transaction record ">
              <Status value={query.data?.transactions_3m} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Verification Status" style={{ height: "100%" }}>
          <Descriptions layout="vertical">
            <Descriptions.Item label="Email">
              <Status value={query.data?.email_verified === false ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">
              <Status value={query.data?.mobile_verified === false ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
            <Descriptions.Item label="EKYC">
              <Status value={query.data?.kyc_id === null ? "FAILED" : "SUCCESS"} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={24}>
        <Tabs
          items={[
            {
              key: "contact_log",
              label: "Contact Log",
              children: renderTab("contact_log"),
            },
          ]}
        />
      </Col>
    </Row>
  );
}

export default UserPage;
