import { CheckOutlined, CloseOutlined, DeleteOutlined, Loading3QuartersOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";

const Status = ({ value }) => {
  const props = {};
  value = value.toUpperCase();
  switch (value) {
    case "ACTIVATED":
    case "ACTIVE":
    case "APPROVED":
    case "SUCCESS":
      props.style = {
        color: "blue",
      };
      props.icon = <CheckOutlined />;
      break;
    case "PENDING":
    case "PROCESSING":
      props.icon = <Loading3QuartersOutlined />;
      break;
    case "CANCELLED":
    case "REJECTED":
    case "FAILED":
    case "FALSE":
    case "NOT BINDING":
    case "NO":
      props.style = {
        color: "red",
      };
      props.icon = <CloseOutlined />;
      break;
    case "DELETED":
      // props.style = {
      //   color: "black",
      // };
      props.icon = <DeleteOutlined />;
      break;
  }
  return <Tag {...props}>{value}</Tag>;
};

export default Status;
